import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store"
import {inject, Injectable} from "@angular/core"
import CdkClientModel from "../cdk/model/cdk-client-model"
import AppFacade from "./facade/app.facade"
import CdkClientInterface from "../cdk/interface/cdk-client-interface"
import {ActionSetClient, ActionSetHideContent, ActionSetLoadingGlobal} from "./app.action"


export interface AppStateInterface {
  client: CdkClientInterface
  loading: boolean,
  hideContent: boolean,
}

@State<AppStateInterface>({
  name: 'appState',
  defaults: {
    client: new CdkClientModel,
    loading: false,
    hideContent: true,
  }
})
@Injectable()
export default class AppState implements NgxsOnInit {

  ngxsOnInit(ctx: StateContext<any>): void {
  }

  private readonly appFacade: AppFacade = inject(AppFacade)

  @Selector()
  static getClient(state: AppStateInterface): CdkClientInterface | Partial<AppStateInterface> | Pick<AppStateInterface, 'client'> {
    return state.client
  }

  @Action(ActionSetClient)
  setClient(context: StateContext<AppStateInterface>, {client}: ActionSetClient): void {
    const state = {...context.getState(), client}
    context.patchState(state)
    this.appFacade.setBootstrap(state)
  }

  @Action(ActionSetLoadingGlobal)
  setLoadingGlobal(context: StateContext<AppStateInterface>, {show}: ActionSetLoadingGlobal) {
    if (show) {
      context.patchState({
        ...context.getState(),
        loading: show
      })
    } else {

      setTimeout(() => {
        context.patchState({
          ...context.getState(),
          loading: show
        })
      }, 1000)
    }
  }

  @Action(ActionSetHideContent)
  setHideContent(context: StateContext<AppStateInterface>, {payload}: ActionSetHideContent) {
    if (payload) {
      context.patchState({
        ...context.getState(),
        hideContent: payload
      })
    } else {
      setTimeout(() => {
        context.patchState({
          ...context.getState(),
          hideContent: payload
        })
      }, 1000)
    }
  }

  @Selector()
  static getLoadingGlobal(state: AppStateInterface): boolean | Partial<AppStateInterface> | Pick<AppStateInterface, 'loading'> {
    return state.loading
  }

  @Selector()
  static getHideContent(state: AppStateInterface): boolean | Partial<AppStateInterface> | Pick<AppStateInterface, 'hideContent'> {
    return state.hideContent
  }

}
